import request from '@/api/request.js'

export function setConfig (data) {
  return request({
    url: '/api/favorite/supplierConfig',
    method: 'POST',
    data
  })
}
 //邀请供应商
export function inviteSupplier (data) {
  return request({
    url: '/api/favorite/inviteSupplier',
    method: 'POST',
    data
  })
}
 //邀请分销商
export function inviteRetailer (data) {
  return request({
    url: '/api/favorite/inviteRetailer',
    method: 'POST',
    data
  })
}
//添加云仓分销商
export function addWarehouse (data) {
  return request({
    url: '/api/supplier/addWarehouse',
    method: 'POST',
    data
  })
}
//编辑云仓分销商
export function editWarehouse (data) {
  return request({
    url: '/api/supplier/editWarehouse',
    method: 'POST',
    data
  })
}
//查看云仓分销商
export function getWarehouse (data) {
  return request({
    url: '/api/supplier/getWarehouse',
    method: 'POST',
    data
  })
}


export function edit (data) {
  return request({
    url: '/api/supplier/edit',
    method: 'POST',
    data
  })
}

export function listSupplier (data) {
  return request({
    url: '/api/supplier/list',
    method: 'POST',
    data
  })
}

// 全站搜索供应商
export function searchSupplier (data) {
  return request({
    url: '/api/supplier/search',
    method: 'POST',
    data
  })
}

// 不再合作
export function disableCooperation (data) {
  return request({
    url: '/api/supplier/disableCooperation',
    method: 'POST',
    data
  })
}

// 添加供应商
export function addSupplier (data) {
  return request({
    url: '/api/supplier/addByCode',
    method: 'POST',
    data
  })
}

// 添加分销商
export function addRetailer (data) {
  return request({
    url: '/api/retailer/addByCode',
    method: 'POST',
    data
  })
}

// 设置分销商结算规则
export function configRetailerSettlement (data) {
  return request({
    url: '/api/retailer/configSettlement',
    method: 'POST',
    data
  })
}

// 设置供应商结算规则
export function configSupplierSettlement (data) {
  return request({
    url: '/api/supplier/configSettlement',
    method: 'POST',
    data
  })
}

// 获取聚水潭授权链接
export function getJstOauthUrl (data) {
  return request({
    url: '/api/supplier/getJstOauthUrl',
    method: 'POST',
    data
  })
}