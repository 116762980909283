import request from '@/api/request.js'

// 上传图片
export function uploadFile(data) {
  return request({
    url: '/api/file/upload',
    method: 'post',
    data
  })
}
// 类目
export function globalCategory(data) {
  return request({
    url: '/api/globalcategory/globalCategory/list',
    method: 'POST',
    data
  })
}

// 城市
export function getCity(data) {
  return request({
    url: '/api/config/city',
    method: 'POST',
    data
  })
}


// 菜单
export function changeMenu(data) {
  return request({
    url: '/api/menu/change',
    method: 'POST',
    data
  })
}
// 供应商
export function mySupplier(data) {
  return request({
    url: '/api/supplier/my',
    method: 'POST',
    data
  })
}
// 团长
export function myRetailer(data) {
  return request({
    url: '/api/retailer/my',
    method: 'POST',
    data
  })
}

// 切换菜单
export function chanageMenu(data) {
  return request({
    url: '/api/menu/change',
    method: 'POST',
    data
  })
}

// 字典
export function dictConfig(data) {
  return request({
    url: '/api/config/dictByCode',
    method: 'POST',
    data
  })
}

// 枚举
export function getEnums(data) {
  return request({
    url: '/api/config/getEnums',
    method: 'POST',
    data
  })
}