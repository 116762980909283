<template>
  <div>
  <el-dialog :title="cangTitle" :visible.sync="classflag" :before-close="handleClose" width="760px">
    
    <el-form ref="formCang" :model="formCang" :rules="ruleCang" label-position="right" label-width="120px">
      <el-form-item label="选择仓库类型" style="margin-bottom:10px" size="mini">
          <el-radio-group v-model="formCang.type">
          <el-radio-button :label="1" :disabled="formCang.id && formCang.type!=1">奇门WMS</el-radio-button>
          <el-radio-button :label="2" :disabled="formCang.id && formCang.type!=2">开团易WMS</el-radio-button>
          <el-radio-button :label="3" :disabled="formCang.id && formCang.type!=3">聚水潭OMS</el-radio-button>
        </el-radio-group>
      </el-form-item>

        <div class="formCangrow">
          <ol>
        <el-form-item label="仓库名称" prop="name">
          <el-input v-model="formCang.name" maxlength="20" style="width:220px" placeholder="请输入限15字以内"></el-input>
        </el-form-item>
          </ol>
          <ol>
             <el-form-item label="仓库编码" prop="code">
          <el-input v-model="formCang.code" placeholder="请输入仓库编码" style="width:220px"></el-input>
        </el-form-item>
          </ol>
        </div>
         <div class="formCangrow">
          <ol>
        <el-form-item label="仓库联系人" prop="owner">
          <el-input v-model="formCang.owner" style="width:220px" placeholder="请输入联系人姓名"></el-input>
        </el-form-item>
          </ol><ol>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="formCang.phone" maxlength="11" style="width:220px" placeholder="11位有效手机号"></el-input>
        </el-form-item>
          </ol>
         </div>

        <el-form-item label="仓库地址" prop="address">
          <!-- <el-select v-model="formCang.cangProvince" placeholder="选择省" @change="checkCangProvince">
                <el-option
                  v-for="item in cangprovinceArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              <el-select v-model="formCang.cangCity" placeholder="选择市" @change="checkCangCity" style="margin-left:16px">
                <el-option
                  v-for="item in cangcityArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              <el-select v-model="formCang.cangDistrict" placeholder="选择地区" style="margin-left:16px">
                <el-option
                  v-for="item in cangareaArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select> -->
          <el-input v-model="formCang.address" style="width:580px;" placeholder="请输入仓库详细地址"></el-input>
        </el-form-item>

        <div class="titless">默认寄件人信息</div>
        <div class="formCangrow">
          <ol>
         <el-form-item label="默认寄件人名称" prop="senderName" style="margin-bottom:10px">
          <el-input v-model="formCang.senderName" style="width:220px" placeholder="请输入默认寄件人名称"></el-input>
        </el-form-item>
          </ol>
        <ol>
        <el-form-item label="默认寄件人电话" prop="senderPhone" style="margin-bottom:10px">
          <el-input v-model="formCang.senderPhone" maxlength="11" style="width:220px" placeholder="请输入默认寄件人电话"></el-input>
        </el-form-item>
          </ol>
        </div> 
        <el-form-item label="默认寄件地址" prop="address">
          <el-select v-model="formCang.senderProvince" placeholder="选择省" @change="checkProvince">
                <el-option
                  v-for="item in provinceArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              <el-select v-model="formCang.senderCity" placeholder="选择市" @change="checkCity" style="margin-left:16px">
                <el-option
                  v-for="item in cityArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              <el-select v-model="formCang.senderDistrict" placeholder="选择地区" style="margin-left:16px">
                <el-option
                  v-for="item in areaArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
          <el-input v-model="formCang.senderAddress" style="width:580px; margin-top:8px" placeholder="请输入默认寄件详细地址"></el-input>
        </el-form-item>
      
      <div v-if="formCang.type==1">
      <div class="titless">奇门仓库信息</div>
      <div class="formCangrow">
          <ol>
           <el-form-item label="奇门货主编码" style="margin-bottom:10px">
          <el-input v-model="formCang.qmCustomerId" style="width:220px" placeholder="请输入奇门货主编码"></el-input>
        </el-form-item>
          </ol>
          <ol>
        <el-form-item label="奇门对接appKey" style="margin-bottom:10px">
          <el-input v-model="formCang.qmTargetAppKey" style="width:220px" placeholder="请输入奇门对接系统appKey"></el-input>
        </el-form-item>
          </ol>

        <ol>
        <el-form-item label="奇门发货物流" style="margin-bottom:10px">          
          <el-select v-model="formCang.qmLogisticsCode" style="width:220px" placeholder="请输入发货物流">
            <el-option label="顺丰" value="SF" ></el-option>
            <el-option label="EMS标准快件" value="EMS" ></el-option>
            <el-option label="EMS经济快件" value="EYB" ></el-option>
            <el-option label="宅急送" value="ZJS" ></el-option>
            <el-option label="圆通" value="YTO" ></el-option>
            <el-option label="中通" value="ZTO" ></el-option>
            <el-option label="百世汇通" value="HTKY" ></el-option>
            <el-option label="百世快运" value="BSKY" ></el-option>
            <el-option label="优速" value="UC" ></el-option>
            <el-option label="申通" value="STO" ></el-option>
            <el-option label="天天快递" value="TTKDEX" ></el-option>
            <el-option label="全峰" value="QFKD" ></el-option>
            <el-option label="快捷" value="FAST" ></el-option>
            <el-option label="邮政小包" value="POSTB" ></el-option>
            <el-option label="国通" value="GTO" ></el-option>
            <el-option label="韵达" value="YUNDA" ></el-option>
            <el-option label="京东配送" value="JD" ></el-option>
            <el-option label="当当宅配" value="DD" ></el-option>
            <el-option label="亚马逊物流" value="AMAZON" ></el-option>
            <el-option label="德邦物流" value="DBWL" ></el-option>
            <el-option label="德邦快递" value="DBKD" ></el-option>
            <el-option label="德邦快运" value="DBKY" ></el-option>
            <el-option label="日日顺" value="RRS" ></el-option>
            <el-option label="跨越物流" value="KYE" ></el-option>
            <el-option label="极兔" value="JTSD" ></el-option>
            <el-option label="其他" value="OTHER" ></el-option>
          </el-select>
        </el-form-item>
          </ol>

       </div>
      </div>

      <div v-if="formCang.type==2">
      <div class="titless">开团易仓库信息</div>
      <div class="formCangrow">
          <ol>
           <el-form-item label="开团易客户ID" style="margin-bottom:10px">
          <el-input v-model="formCang.ktyCustomerId" style="width:220px" placeholder="请输入开团易客户ID"></el-input>
        </el-form-item>
          </ol>          

          <ol>
           <el-form-item label="开团易仓库ID" style="margin-bottom:10px">
          <el-input v-model="formCang.ktyWarehouseId" style="width:220px" placeholder="请输入开团易仓库ID"></el-input>
        </el-form-item>
          </ol>          
       </div>
      </div>

      <div v-if="formCang.type==3">
      <div class="titless">聚水潭OMS直连信息</div>
      <div class="formCangrow">
          <ol>
           <el-form-item label="自有商城店铺ID" style="margin-bottom:10px">
          <el-input v-model="formCang.jstShopId" style="width:220px" placeholder="请输入聚水潭自有商城店铺ID"></el-input>
        </el-form-item>
          </ol>          

          <ol>
            <el-form-item label="账号是否已授权" style="margin-bottom:10px">
                <span v-if="formCang.jstEnableOauth == 1"><el-tag type="success">已授权</el-tag></span>
                <span v-else><el-button type="danger" plain @click="jstOauth">尚未授权，点击立即授权</el-button></span>
            </el-form-item>
          </ol>
       </div>
       <div style="margin:5px 0px 0px 15px;"><span class="el-form-item__label">物流同步回调地址：</span><span class="el-form-item__content">https://www.kaituanyi.com/api/router/wms/jushuitan/delivery</span></div>
       <div style="margin:0px 0px 0px 15px;"><span class="el-form-item__label">取消订单回调地址：</span><span class="el-form-item__content">https://www.kaituanyi.com/api/router/wms/jushuitan/cancel</span></div>
      </div>

        <div class="midCss">
          <el-button type="primary" :loading="logining" @click="handleSubmit('formCang')" size="medium">确认</el-button>
          <el-button @click="handleClose" size="medium">取 消</el-button>
        </div>
    </el-form>
  </el-dialog>

  <el-dialog title="授权聚水潭OMS" 
  :visible.sync="showJstQrCodeDialog"
  @close="closeJstQrCodeDialog"
  center
    width="760"
    >
    <p style="font-size:14px;color:red;width:500px;margin:0px auto 30px auto;">请扫描二维码，输入聚水潭账号进行授权，授权后可向该聚水潭账号推送订单</p>
    <div id="qrCodeId" style="width:200px;margin:0 auto;"></div>

  </el-dialog>

  </div>
</template>

<script>
import { addWarehouse, editWarehouse, getWarehouse, getJstOauthUrl } from "@/api/supplier";
import { getCity } from "@/api/common";
import QRCode from'qrcodejs2'

let qrcode

export default {
  name: 'addcangku',
  props: ['isShow', 'rowdata'],
  data () {
    return {
      cangTitle: '添加云仓',
      formCang: {
        "address": "",
        "type": 1,
        "code": "",
        "name": "",
        "owner": "",
        "phone": "",
        "priority": 1,
        "property": 0,
        "cangProvince": '',
        "cangCity": '',
        "cangDistrict": '',
        "senderProvince": '',
        "senderCity": '',
        "senderDistrict": '',
        "senderPhone": '',
        "senderName": '',
        "senderAddress": ''
      },
      cangData: [],
      ruleCang: {
        name: [{ required: true, message: '请输入仓库名称', trigger: 'blur' }],
        priority: [{ required: true, message: '请输入发货排序', trigger: 'blur' }],
        owner: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        senderName: [{ required: true, message: '请输入寄件人姓名', trigger: 'blur' }],
        senderPhone: [{ required: true, message: '请输入寄件人电话', trigger: 'blur' }],
        senderAddress: [{ required: true, message: '请输入寄件地址', trigger: 'blur' }],
      },
      logining: false,
      classflag: false,
      provinceArr: [],
      cityArr: [],
      areaArr: [],
      cangprovinceArr: [],
      cangcityArr: [],
      cangareaArr: [],
      showJstQrCodeDialog: false
    }
  },
  watch: {
    isShow: {
      deep: true,
      handler (newVal, oldVal) {
        console.log(oldVal)
        this.classflag = newVal
        if(newVal){
          this.getCityList()
        }
      }
    },
    rowdata: {
      deep: true,
      handler (newVals, oldVals) {
        console.log(newVals)
        console.log('***********')
        console.log(oldVals)
       
        if(newVals){
          this.cangTitle = '编辑云仓'
          this.cangData = newVals
          this.getYuncang()
        }else{
          this.cangTitle = '添加云仓'
          this.cangData = [],
          this.formCang = {
            "address": "",
            "type": 1,
            "code": "",
            "name": "",
            "owner": "",
            "phone": "",
            "priority": 1,
            "property": 0,
            "cangProvince": '',
            "cangCity": '',
            "cangDistrict": '',
            "senderProvince": '',
            "senderCity": '',
            "senderDistrict": '',
            "senderPhone": '',
            "senderName": '',
            "senderAddress": ''
          }
        }
      }
    }
  },
  methods: {
    handleClose(){
      this.classflag = false
      this.$emit('cancalfunc', false)
    },
    jstOauth() {
      if(!this.formCang.id) {
        this.$message.warning("请先保存仓库信息后再点击授权")
        return
      }
      getJstOauthUrl({id: this.formCang.id}).then(res => {
        if(res.code === 200) {
          this.showJstQrCodeDialog = true
          this.createQrCode(res.data)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    closeJstQrCodeDialog() {
      this.showJstQrCodeDialog = false
      this.handleClose()
    },
    createQrCode(text) {
      this.$nextTick (function () {
        if(!document.getElementById("qrCodeId")) return;
        document.getElementById("qrCodeId").innerHTML = ""
        qrcode = new QRCode("qrCodeId", {
            text: text,
            width: 200,
            height: 200,
            colorDark:'#000000',
            colorLight:'#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    getCityList(){
      var that = this
      var objs = {}
      getCity(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.provinceArr = res.data.districts[0].districts;
          that.cangprovinceArr = res.data.districts[0].districts;
          that.cityArr = []
          that.areaArr = []
          that.cangcityArr = []
          that.cangareaArr = []
          if(that.formCang.id){
            that.provinceArr.map((item) => {
              if(item.name == that.formCang.senderProvince){
                that.cityArr = item.districts
                that.cangcityArr = item.districts
              }
            })
            console.log('城市列表')
            that.cityArr.map((item) => {
              if(item.name == that.formCang.senderCity){
                that.areaArr = item.districts
                that.cangareaArr = item.districts
              }
            })
          }
        }else{
          that.$message.error(res.message)
        }
      })
    },
    checkProvince(val){
      console.log(val)
      var that = this
      that.provinceArr.map((item, key) => {
        if(item.name == val){
          that.cityArr = item.districts
          that.areaArr = []
          that.formCang.senderCity = '';
          that.formCang.senderDistrict = '';
        }
      })
    },
    checkCity(val){
      var that = this
      that.cityArr.map((item, key) => {
        if(item.name == val){
          that.areaArr = item.districts
          that.formCang.senderDistrict = '';
        }
      })
    },
    checkCangProvince(val){
      console.log(val)
      var that = this
      that.cangprovinceArr.map((item, key) => {
        if(item.name == val){
          that.cangcityArr = item.districts
          that.cangareaArr = []
          that.formCang.cangCity = '';
          that.formCang.cangDistrict = '';
        }
      })
    },
    checkCangCity(val){
      var that = this
      that.cangcityArr.map((item, key) => {
        if(item.name == val){
          that.cangareaArr = item.districts
          that.formCang.cangDistrict = '';
        }
      })
    },
    getYuncang(){
      var that = this
      const param = {
        "id": that.cangData
      }
      getWarehouse(param).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.formCang = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleSubmit(formName){
      let that = this
      that.$refs[formName].validate((valid) => {
      if (valid) {
        that.logining = true
        that.formCang.role = window.sessionStorage.getItem("role")
          if(that.formCang.id){
            editWarehouse(that.formCang).then(res => {
              that.logining = false
              if (parseInt(res.code) === 200) {
                that.$message({
                  message: '云仓修改成功',
                  type: 'success'
                });
                setTimeout(() => {
                  that.classflag = false
                }, 500)
                that.$emit('cancalfunc', true)
              } else {
                this.$message.error(res.message)
              }
            })

          }else{
            addWarehouse(that.formCang).then(res => {
              that.logining = false
              if (parseInt(res.code) === 200) {
                that.$message({
                    message: '新增云仓成功',
                    type: 'success'
                  });
                that.formCang.name = ''
                that.$emit('cancalfunc', true)
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.midCss {
  text-align: left;
  display: block;
  padding-left: 100px;
  padding-top: 20px;
}
.formCangrow{
  display: block;
  overflow: hidden;
}
.formCangrow ol{
  float: left;
  width: 50%;
}
.tips {
  font-size: 12px;
  color: #ff7200;
  padding-left:20px;
}
.titless{
   background-color: #f5f6f8;
  line-height: 40px;
  margin:0px 0px 10px 0px;
  font-size:15px;
  padding-left:20px;
  font-weight: bold;
  color: #282828;
}
</style>
